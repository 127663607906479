import { BoardSectionItemBavariaMap } from './BoardSectionItemBavariaMap';
import styles from './BoardSectionItemMap.module.scss';

type Props = {
	selectedDistrict: string | null | undefined;
};

export function BoardSectionItemMap({ selectedDistrict }: Props) {
	return (
		<nav className={styles.wrapper} aria-label="Wählen Sie Nachrichten aus Ihrer Region" data-theme="dark">
			<div className={styles.maxHeightWrapper}>
				<h3 className={`heading4 ${styles.header}`} id="mapHeader">
					Wählen Sie Nachrichten aus Ihrer Region
				</h3>

				<BoardSectionItemBavariaMap className={styles.map} selectedDistrict={selectedDistrict} />
			</div>
		</nav>
	);
}
